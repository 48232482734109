import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9cf3997a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=9cf3997a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cf3997a",
  null
  
)

export default component.exports
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QPage from 'quasar/src/components/page/QPage.js'
import QForm from 'quasar/src/components/form/QForm.js'
import QInput from 'quasar/src/components/input/QInput.js'
import QSelect from 'quasar/src/components/select/QSelect.js'
import QItem from 'quasar/src/components/item/QItem.js'
import QItemSection from 'quasar/src/components/item/QItemSection.js'
import QItemLabel from 'quasar/src/components/item/QItemLabel.js'
import QBtn from 'quasar/src/components/btn/QBtn.js'
import QList from 'quasar/src/components/item/QList.js'
import QImg from 'quasar/src/components/img/QImg.js'
import QDialog from 'quasar/src/components/dialog/QDialog.js'
import QCard from 'quasar/src/components/card/QCard.js'
import QCardSection from 'quasar/src/components/card/QCardSection.js'
import QCardActions from 'quasar/src/components/card/QCardActions.js'
qInstall(component, 'components', {QPage,QForm,QInput,QSelect,QItem,QItemSection,QItemLabel,QBtn,QList,QImg,QDialog,QCard,QCardSection,QCardActions})
